import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (

        <div >
            <footer aria-label="Site Footer" className="bg-main-600 lg:grid z-10 lg:grid-cols-5">
                <div className="relative block h-32 lg:col-span-2 lg:h-full">
                    <img
                        src="assets/Footer/footer.jpg"
                        alt=""
                        className="absolute inset-0 object-cover w-full h-full"
                    />
                </div>

                <div className="px-4 py-16 sm:px-6 lg:col-span-3 lg:px-8">
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
                        <div className='md:mx-auto'>
                            {/* <p>
                                <span className="text-xs tracking-wide text-gray-100 uppercase">
                                    Call us
                                </span>

                                <a
                                    href="#"
                                    className="block text-2xl font-medium text-gray-200 hover:opacity-75 sm:text-3xl"
                                >
                                    9922930522
                                </a>
                            </p> */}
                            <img className='w-[120px] md:mx-auto ' src='assets/logo/footerlogo.png'></img>
                            <ul className="mt-8 space-y-1  text-[15px] text-gray-200">
                                <li>Trusted firm for each & every solution</li>
                                <li> in TEXTILE SPINNING</li>
                            </ul>

                        </div>

                        <div className="grid grid-cols-1  gap-4 sm:grid-cols-2">
                            <div>
                                <p className="font-medium text-gray-100">Quick Link</p>

                                <nav aria-label="Footer Navigation - Services" className="mt-6">
                                    <ul className="space-y-4 text-sm">
                                        <li>
                                            <Link to="/">  <a className="text-gray-200 transition hover:opacity-75">
                                                Home
                                            </a></Link>
                                        </li>

                                        <li>
                                            <Link to="/about">  <a className="text-gray-200 transition hover:opacity-75">
                                                About Us
                                            </a></Link>
                                        </li>

                                        <li>
                                            <Link to="/product">  <a className="text-gray-200 transition hover:opacity-75">
                                                Products
                                            </a></Link>
                                        </li>

                                        <li>
                                            <Link to="/services">  <a className="text-gray-200 transition hover:opacity-75">
                                                Services
                                            </a></Link>
                                        </li>

                                        <li>
                                            <Link to="/gallery" >  <a className="text-gray-200 transition hover:opacity-75">
                                                Gallery
                                            </a></Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>

                            <div>
                                <p className="font-medium text-gray-100">Contact</p>

                                <nav aria-label="Footer Navigation - Company" className="mt-6">
                                    <ul className="space-y-4 text-sm">
                                        <li>
                                            <a href={`tel:${9922930522}`} className="text-gray-200 transition hover:opacity-75">
                                                +91 9922930522
                                            </a>
                                        </li>

                                        <li>
                                            <a href="mailto:ljsbexports22@gmail.com" className="text-gray-200 transition hover:opacity-75">
                                                ljsbexports22@gmail.com
                                            </a>
                                        </li>

                                        <li>
                                            <a href="mailto:ljtex21@gmail.com" className="text-gray-200 transition hover:opacity-75">
                                                ljtex21@gmail.com </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div className="pt-4 mt-12 border-t border-gray-100">
                        {/* <div className="sm:flex sm:items-center ">
                            <a href='/login' className="mt-8 mb-4 text-xs hover:text-white text-gray-400 sm:mt-0"><span className='pl-1'> Admin Login</span></a>
                        </div> */}
                        <div className="sm:flex sm:items-center ">
                            <p className="mt-8 text-xs text-gray-400 sm:mt-0">
                                &copy; 2023. LJTEX.
                            </p><a href='https://5techg.com/' className="mt-8 text-xs hover:text-white text-gray-400 sm:mt-0"><span className='pl-1'> Design & Developed by 5Tech G</span></a>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer