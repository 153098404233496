import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import 'firebase/compat/firestore';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA_wWIyZphtxukFFqU1Ka4nELKd_pusKXA",
    authDomain: "ljtex-baf0c.firebaseapp.com",
    projectId: "ljtex-baf0c",
    storageBucket: "ljtex-baf0c.appspot.com",
    messagingSenderId: "956992567371",
    appId: "1:956992567371:web:0264b9a6f7dddd7f44b5ce",
    measurementId: "G-7B3DHMJW7N"
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();
export const storage = firebase.storage();
// export const database = firebase.database();