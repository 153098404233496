import './contact.css'
import { send } from "emailjs-com";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';



function Contact() {
    const [countryCodes, setCountryCodes] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState("+91");
    const [isSending, setIsSending] = useState(false);
    const [formError, setFormError] = useState(null);

    const [toSend, setToSend] = useState({
        UserName: "",

        email: "",
        phone: "",
        address: "",
        message: "",
    });

    useEffect(() => {
        const fetchCountryCodes = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v2/all');
                const countries = response.data;
                const codes = countries.map((country) => ({
                    label: `+${country.callingCodes[0]} (${country.name})`,
                    value: country.callingCodes[0],
                }));
                setCountryCodes(codes);
            } catch (error) {
                console.error('Error fetching country codes:', error);
            }
        };

        fetchCountryCodes();
    }, []);

    const handleCountryCodeChange = (e) => {
        setSelectedCountryCode(e.target.value);
    };
    // const handleCountryCodeChange = (e) => {
    //     setSelectedCountryCode(e.target.value);
    //     setToSend({
    //         ...toSend,
    //         country_code: e.target.value, // Adding the selected country code to the toSend object
    //     });
    // };

    useEffect(() => {
        const ifameData = document.getElementById("headOffice")
        const lat = 17.279783942551465;
        const lon = 74.54544640114284;
        ifameData.src = `https://maps.google.com/maps?q=${lat},${lon}&hl=es;&output=embed`
    })
    useEffect(() => {
        const ifameData = document.getElementById("branchOffice")
        const lat = 16.683552661821487;
        const lon = 74.46206974267501;
        ifameData.src = `https://maps.google.com/maps?q=${lat},${lon}&hl=es;&output=embed`
    })

  
    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const requiredFields = ["UserName", "email", "phone", "address", "message"];
        const hasEmptyField = requiredFields.some((field) => !toSend[field]);
        if (hasEmptyField) {
            setFormError("Please fill all fields.");
            return;
        }

        setIsSending(true);
        setFormError(null);
        send(
            "service_cwvkkrf",
            "template_vpnmpd9",
            {
                ...toSend,
                country: selectedCountryCode, // Add the selected country code to the object
            },
            "CYG5oYv0iJlGQjq3G"
        )
            .then((response) => {
                console.log("SUCCESS!", response.status, response.text);
                setIsSending(false);
                alert("Thanks for connecting us, we will reply shortly.")
                // toast.success(
                //     "Congratulations! You have."
                // );
            })
            .catch((err) => {
                console.log("FAILED...", err);
                setIsSending(false)
                // toast.error("Something went wrong. Please try again later.");
            });
    };




    return (
        <>
            <div>
                <img className='w-full' alt='' src='assets/banner/contact.svg'></img>
            </div>

            <div className='grid mt-6  gap-8 md:grid-cols-4 mx-16'>
                <div className='border shadow-lg  py-4'>
                    <img className='mx-auto pb-4' src='assets/contact/calll.svg' alt=''></img>
                    <h4 className='text-center pb-3 font-Roboto font-semibold text-xl text-main-600'>Call us </h4>
                    <a href={`tel:${9922930522}`}><p className='text-center'>+91 9922930522</p></a>
                </div>
                <div className='border shadow-lg  py-6'>
                    <img className='mx-auto pb-4' src='assets/contact/1mail.svg' alt=''></img>
                    <h4 className='text-center pb-3  font-Roboto font-semibold text-xl text-main-600 '>Mail us </h4>
                    <a href="mailto:ljtex21@gmail.com"> <p className='text-center pb-3'>ljtex21@gmail.com </p></a>
                    <a href="mailto:ljsbexports22@gmail.com">  <p className='text-center'>ljsbexports22@gmail.com  </p></a>

                </div>
                <div className='border shadow-lg  py-6'>
                    <img className='mx-auto pb-4' src='assets/contact/location1.svg' alt=''></img>
                    <h4 className='text-center pb-3  font-Roboto font-semibold text-xl text-main-600 '>Head Office</h4>
                    <a href='https://www.google.com/maps/dir//17.2797839,74.5454464/@17.2797839,74.5454464,16z?entry=ttu'>   <p className='text-center px-6 pb-3'>Behind Lucky sagar tiles, yaswant nagar, Lengare Road, Vita (415311). Dist - Sangli, Maharashtra. </p></a>

                </div>
                <div className='border shadow-lg  py-6'>
                    <img className='mx-auto pb-4' src='assets/contact/location1.svg' alt=''></img>
                    <h4 className='text-center pb-3  font-Roboto font-semibold text-xl text-main-600 '>Branch Office</h4>
                    <a href='https://www.google.com/maps/dir//16.6835527,74.4620697/@16.6835527,74.4620697,16z?entry=ttu'> <p className='text-center px-6 pb-3'>Opp. Kalika sound, Near sakharpe hospital A/P -lchalkaranji (416115). Dist - Kolhapur, Maharashtra. </p></a>

                </div>

            </div>

            <div className='grid mt-20  mb-10 xs:grid-cols-1 lg:grid-cols-2'>
                <div className='col-span-1'>
                    <div className='md:ml-20 '>
                        <img className='h-[500px]' src='assets/contact/contact (1).jpg' alt=''></img>
                    </div>
                </div>
                <div className=' md:ml-10 col-span-1'>

                    <h1 className=' xs:mx-auto  font-bold  xs:text-[40px] py-6 text-3xl font-Roboto '>Drop us Message</h1>
                    <form class="w-full   mt-4 max-w-xl" onSubmit={onSubmit} id="specific-div">
                        {formError && <p className="text-red-500 mb-2">{formError}</p>}
                        
                        <div class="flex  flex-wrap md:-mx-3 mb-6">
                            <div class="w-full xs:mb-2 md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                    Your Name
                                </label>
                                <input value={toSend.UserName} name="UserName"
                                    onChange={handleChange}
                                    class="appearance-none block w-full bg-gray-100 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Enter Name" />
                            </div>
                            <div class="w-full xs:mb-5 md:w-1/2 px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                    Your Email
                                </label>
                                <input value={toSend.email} name="email"
                                    onChange={handleChange} class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="email" placeholder="Enter Email" />
                            </div>
                            <div class="w-full xs:mb-2 md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                    Contact Number
                                </label>
                                <div className='flex'>
                                    {/* <Select
                                    options={countryCodes}
                                    value={selectedCountryCode}
                                    onChange={handleCountryCodeChange}
                                /> */}
                                    {/* <select className='w-12 border h-11 bg-gray-100 ' value={selectedCountryCode} onChange={handleCountryCodeChange}>
                                        <option value="">+91 (india)</option>
                                        {countryCodes.map((country) => (
                                            <option className='' key={country.value} value={country.value}>
                                                {country.label}
                                            </option>
                                        ))}
                                    </select> */}
                                    <select
                                        className="w-12 border h-11 bg-gray-100"
                                        value={selectedCountryCode}
                                        onChange={handleCountryCodeChange}
                                        name="country Code" // Add the name attribute for the country code select
                                    >
                                        <option value="">+91 (India)</option>
                                        {countryCodes.map((country, index) => (
                                            <option className="" key={index} value={country.value}>
                                                {country.label}
                                            </option>
                                        ))}
                                    </select>

                                    <input value={toSend.phone} name="phone"
                                        onChange={handleChange} class="appearance-none block w-full bg-gray-100 text-gray-700 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="number" placeholder="Enter Number" />

                                </div>
                            </div>
                            <div class="w-full xs:mb-5 md:w-1/2 px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    City
                                </label>
                                <input value={toSend.address} name="address"
                                    onChange={handleChange} class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="City Name" />
                            </div>

                        </div>
                        <div class="flex flex-wrap -mt-4 md:-mx-3 ">
                            <div class="w-full px-3">
                                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" >
                                    Massage
                                </label>
                                <textarea value={toSend.message} name="message"
                                    onChange={handleChange} class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-100 rounded py-3 px-4 mb-2  focus:outline-none focus:bg-white focus:border-gray-500" type="text" placeholder="Enter message" />
                            </div>
                        </div>
                        <button
                            type='submit'
                            className='mt-4 h-10 bg-[#D9D9D9] text-black text-lg font-bold hover:bg-main-600 hover:text-white border-2 w-full'
                            disabled={isSending} // Disable the button while sending is in progress
                        >
                            {isSending ? "Sending..." : "Send"}
                        </button>
                        {/* <button type='submit' className='  mt-4 h-10 bg-[#D9D9D9] text-black  text-lg font-bold hover:bg-main-600 hover:text-white border-2 w-full'>Send</button> */}
                    </form>
                    <div>  <ToastContainer /></div>
                </div>
            </div>
            <div className='grid gap-10 md:grid-cols-2'>
                <div className='col-span-1'>
                    <h4 className=' md:ml-10 border-b-[3.5px] w-40 border-black pb-3  font-Roboto font-semibold text-2xl '>Head Office </h4>
                    <div className='mt-10 '>
                        <iframe id="headOffice" height="500px" width="100%"></iframe>
                    </div>
                </div>
                <div className='col-span-1'>
                    <h4 className=' md:ml-10 border-b-[3.5px] w-40 border-black pb-3  font-Roboto font-semibold text-2xl '>Branch Office </h4>
                    <div className='mt-10 '>
                        <iframe id="branchOffice" height="500px" width="100%"></iframe>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Contact