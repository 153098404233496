import React from 'react'

function About() {
    return (

        <div className=''>
            <div>
                <img className='w-full' src='assets/banner/aboutUs.svg'></img>
            </div>
         <div className='border md:mx-40 my-20'>
            <h1 className=' pt-10 font-baloobhai text-center font-semibold text-4xl text-main-600'>About Us</h1>
            <div className='px-6  my-8'>
                <p className='font-Roboto'>We have two firms L.J.S.B. Exports Pvt. Ltd. & L.J. Tex. We Trade in all types of COTTON YARN, COTTON, FABRIC & COTTON WASTE. Also we provide TEXTILE SPINNING CONSULTANCY to Upcoming Spinning units as well as existing spinning units. </p><br/>
                <p className='font-Roboto'>Our head office is situated at VITA (415311), Dist. - Sangli, Maharashtra (INDIA) & Branch office at ICHALKARANJI (416115), Dist. Kolhapur, Maharashtra (INDIA).  </p> <br/>
                <p className='font-Roboto'>The promoter of our company is a TEXTILE ENGINEER & have been worked in TEXTILE SPINNING field on the top most position for more than two decades. </p><br/>
                <p className='font-Roboto'>In year 2021 they decided to utilize their knowledge and experience of the sector to benefit clients. This is what motivated them to establish the company. Today we hailed our self for being a talented provider of a wide variety of yarn, cotton, Fabric & Cotton Waste.</p> <br/>
                <p className='font-Roboto'>Within just a short span of operation in the market we secured a good market position and turned over great sale figures. Quickly, we earned the trust of many clients.</p><br/>
                <p className='font-Roboto'>In addition to above our enterprise focuses on working as a customer oriented service provider as Textile Spinning Consultancy Services, we pledge to serve each and every client with a quality accredited assortment of consultancy.</p>
            </div>
         </div>
            

        </div>
    )
}

export default About