import React from 'react'
import Home from '../Home/Home'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import About from '../About/About'
import Contact from '../Contact/Contact'
import Service from '../Services/Service'
import Gallery from '../Gallery/Gallery'
import Produc from '../Product/Product'
import ProductDetails from '../Product/ProductDetails'
// import ProductForm from '../adminPanel/ProductForm'
// import Adminpage from '../adminPanel/Firebase/Adminpage'
import Crud from '../adminPanel/Firebase/Crud'
import Login from '../adminPanel/Firebase/Login/Login'


function Page() {


    return (
        <div><BrowserRouter>

            <Header />

            <Routes  >
                <Route exact path="/" element={<Home />} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/contact" element={<Contact />} />
                <Route exact path="/services" element={<Service />} />
                <Route exact path="/product" element={<Produc />} />
                <Route exact path="/gallery" element={<Gallery />} />
                <Route exact path="/admin" element={<Crud />} />
                <Route exact path="/login" element={<Login />} />
                <Route path="/details/:id" element={<ProductDetails />} />
            </Routes>
            {/* footer */}
            <Footer />
        </BrowserRouter></div>
    )
}

export default Page