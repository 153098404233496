import React, { useState, useRef, useEffect } from 'react'
import "./home.css"
import "./home.scss"
import Partner from './Partner'
import Testimonial from './Testimonial'
// import PauseOnHover from './CardSlider/CardSlider'
import BannerCarousel from '../BannerCarousel/BannerCarousel'



function Home() {

  // let [product, setProduct] = useState(data);
  // const [isPlaying, setIsPlaying] = useState(false);
  // const videoRef = useRef(null);

  // const togglePlay = () => {
  //   const video = videoRef.current;
  //   if (isPlaying) {
  //     video.pause();
  //   } else {
  //     video.play();
  //   }
  //   setIsPlaying(!isPlaying);

  //   if (video) {
  //     if (video.requestFullscreen) {
  //       video.requestFullscreen();
  //     } else if (video.mozRequestFullScreen) {
  //       video.mozRequestFullScreen();
  //     } else if (video.webkitRequestFullscreen) {
  //       video.webkitRequestFullscreen();
  //     } else if (video.msRequestFullscreen) {
  //       video.msRequestFullscreen();
  //     }
  //   }
  // };



  // useEffect(() => {
  //   const video = videoRef.current;

  //   const handleVideoEnd = () => {
  //     setIsPlaying(false);
  //   };

  //   video.addEventListener('ended', handleVideoEnd);

  //   return () => {
  //     video.removeEventListener('ended', handleVideoEnd);
  //   };
  // }, []);


  return (



    <div className='z-10'>
      <div >
        <BannerCarousel />
      </div>

      {/* <div className='lg:mx-20 xs:mx-10 xs:my-6'>
        <div>
          <h1 className='text-center font-medium font-serif xs:text-2xl md:text-4xl xs:pt-4 md:pt-10'> Welcome to Brothers Indrusties</h1>
          <div className='w-40 h-1.5 mt-3 bg-red-800 rounded-lg mx-auto'></div>
          <h4 className='text-center font-serif text-[16px] lg:w-[1000px] mx-auto pt-8'>Brothers Indrusties is a Modern Machine Shop That Combines Technology Advanced Maching Equipment's With Highly Experienced Staff And Professional Approach.</h4>
        </div>
        <div className='mt-8'> <PauseOnHover /></div>
      </div> */}

      {/* <section className=''>
        <h1 className='text-center font-medium font-serif xs:text-xl md:text-4xl xs:pt-4 md:mt-20'> Why us</h1>
        <div className='w-40 h-1.5 mt-3 bg-red-800 rounded-lg mx-auto'></div>
        <h4 className='text-center font-serif text-[16px] lg:w-[1000px] mx-auto pt-8'>Brothers Indrusties is a Modern Machine Shop That Combines Technology Advanced Maching Equipment's With Highly Experienced Staff And Professional Approach.</h4>
        <div className=' lg:flex mt-10 '>
          <div className='col-span-1 xs:w-full lg:w-[60%] ' >
            <div className="video-player ">
              <video controls ref={videoRef} src="newimage/video/5TECHg.mp4" />
              <div
                className={`lg:play-pause-button ${isPlaying ? 'pause' : ''}`}
                onClick={togglePlay}
              />
            </div>
          </div>

          <div className='lg:w-[40%]'>
            <h2 className=' xs:text-center xs:pt-10 lg:pt-40 text-center text-4xl font-medium' style={{ fontFamily: "Roboto" }}>Focusing on strategic decisions & practical actions
            </h2>
          </div>
        </div>
      </section> */}

      {/* <div>
        <h1 className='text-center font-medium font-serif xs:text-xl md:text-4xl xs:pt-4 md:mt-20'> Our Services</h1>
        <div className='w-40 h-1.5 mt-3 bg-red-800 rounded-lg mx-auto'></div>
        <h4 className='text-center font-serif text-[16px] lg:w-[1000px] mx-auto pt-8'>Brothers Indrusties is a Modern Machine Shop That Combines Technology Advanced Maching Equipment's With Highly Experienced Staff And Professional Approach.</h4>

        <div className='grid xs:grid-cols-1 xs:gap-4 xs:mx-3 md:grid-cols-2 xl:grid-cols-3 mt-10 lg:mx-20 '>

          <figure className="image-block col-span-1 md:mx-auto cursor-pointer  xs:w-40 xs:h-[470px] xs:min-w-fit  ">
            <img src="newimage/products/FOUNDRY FACILITIES.jpg" alt="" />
            <figcaption>
              <h3>
                FOUNDRY FACILITIES
              </h3>
              <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
              <button>
                More Info
              </button>
            </figcaption>
          </figure>
          <figure className="image-block col-span-1 md:mx-auto cursor-pointer xs:w-40 xs:h-[470px] xs:min-w-fit  ">
            <img className='h-full' src="newimage/products/quality control.png" alt="" />
            <figcaption>
              <h3>
                QUALITY CONTROL
              </h3>
              <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
              <button>
                More Info
              </button>
            </figcaption>
          </figure>
          <figure className="image-block col-span-1 md:mx-auto cursor-pointer xs:w-40 xs:h-[470px] xs:min-w-fit  ">
            <img src="newimage/products/Product development.jpg" alt="" />
            <figcaption>
              <h3>
                PRODUCT DEVELOPMENT
              </h3>
              <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
              <button>
                More Info
              </button>
            </figcaption>
          </figure>

        </div>
        <div className='grid xs:grid-cols-1 xs:gap-4 xs:mx-3 xs:mt-6 md:grid-cols-2 xl:grid-cols-3 lg:mx-20 lg:mt-20 '>

          <figure className="image-block col-span-1 md:mx-auto cursor-pointer xs:w-40 xs:h-[470px] xs:min-w-fit  ">
            <img src="newimage/products/cncMachine.jpg" alt="" />
            <figcaption>
              <h3>
                MACHINING FACILITIES
              </h3>
              <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
              <button>
                More Info
              </button>
            </figcaption>
          </figure>
          <figure className="image-block col-span-1 md:mx-auto cursor-pointer xs:w-40 xs:h-[470px] xs:min-w-fit  ">
            <img className='h-full' src="newimage/products/packaging.jpg" alt="" />
            <figcaption>
              <h3>
                PAINTING AND PACKAGING
              </h3>
              <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
              <button>
                More Info
              </button>
            </figcaption>
          </figure>
          <figure className="image-block col-span-1 md:mx-auto cursor-pointer xs:w-40 xs:h-[470px] xs:min-w-fit  ">
            <img src="newimage/products/shiping.jpg" alt="" />
            <figcaption>
              <h3>
                LOGISTICS
              </h3>
              <p>Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vestibulum tortor quam, feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu libero sit amet quam egestas semper. Aenean ultricies mi vitae est. Mauris placerat eleifend leo.</p>
              <button>
                More Info
              </button>
            </figcaption>
          </figure>

        </div>
      </div> */}


      {/* <div>
        <Partner />
      </div>
      <div >
        <Testimonial />
      </div> */}

    </div>
  )
}

export default Home