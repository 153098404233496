import React, { useState, useEffect } from "react";
import { storage, firestore } from "./firebase";
import './spinner.css'
import { v4 as uuidv4 } from 'uuid';
import { Link } from "react-router-dom";


function Crud() {
    const [file, setFile] = useState(null);
    const [title, setTitle] = useState("");
    const [price, setPrice] = useState("");
    const [placeholder, setPlaceholder] = useState("");
    const [category, setCategory] = useState("")
    const [minimumOrderQuantity, setMinimumOrderQuantity] = useState("");
    const [use, setUse] = useState("");
    const [material, setMaterial] = useState("");
    const [product_description, setproduct_description] = useState([]);
    const [editedProduct, setEditedProduct] = useState(null);
    const [editedFile, setEditedFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false)
    const [showAlert, setShowAlert] = useState(false);
    const [updateAlert, setUpdateAlert] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false); 
    const [isLoading, setIsLoading] = useState(true); 
    const [additionalFields, setAdditionalFields] = useState([]);
    const [editedAdditionalFields, setEditedAdditionalFields] = useState(null);

    const addField = () => {
        setAdditionalFields([...additionalFields, ""]);
    };

    const handleAdditionalFieldChange = (index, value) => {
        const cleanedValue = value.replace(/,/g, ''); // Remove commas from the value
        const fields = [...additionalFields];
        fields[index] = cleanedValue;
        setAdditionalFields(fields);
        // const fields = [...additionalFields];
        // fields[index] = value;
        // setAdditionalFields(fields);
    };
    const removeField = (index) => {
        const fields = [...additionalFields];
        fields.splice(index, 1);
        setAdditionalFields(fields);
    };


    // edit functionality 
    const handleSave = async (editedProduct, editedAdditionalFields) => {
        try {
            setIsUpdating(true)
            if (editedFile) {
                const storageRef = storage.ref();
                const fileRef = storageRef.child(editedFile.name);
                await fileRef.put(editedFile);
                const url = await fileRef.getDownloadURL();
                editedProduct.url = url;
                await firestore
                    .collection("product_description")
                    .doc(editedProduct.id)
                    .update(editedProduct);
            } else {
                await firestore
                    .collection("product_description")
                    .doc(editedProduct.id)
                    .update(editedProduct);
            }
            setEditedProduct(null);
            setEditedFile(null);
            setIsUpdating(false)
            setUpdateAlert(true);

            setTimeout(() => {
                setUpdateAlert(false);
            }, 2000);
            // alert("Product updated successfully");
        } catch (error) {
            console.error("Error updating product:", error);
            setIsUpdating(false)
        }
    };


    const handleCancel = () => {
        setEditedProduct(null);
    };

    const handleFileChangeEdit = (e) => {
        setEditedFile(e.target.files[0]);
    };


    // const handleEdit = (product) => {
    //     setEditedProduct(product);
    // };
    const handleEdit = (product) => {
        setEditedProduct(product);
        setShowEditModal(true); 
        setFile(product.url)
        setEditedAdditionalFields(
            product.additionalFields ? product.additionalFields.split(",") : []
        );
    };

    const handleCancelEdit = () => {
        setEditedProduct(null);
        setShowEditModal(false); 
    };

    useEffect(() => {
     
        const fetchImages = async () => {
            try {
                const snapshot = await firestore.collection("product_description").get();
                const imagesData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setproduct_description(imagesData);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching images:", error);
                setIsLoading(false);
            }
        };

        fetchImages();
    }, []);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };
    const handleCategoryChange = (e) => {
        setCategory(e.target.value);
    };

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };
    const handlePriceChange = (e) => {
        setPrice(e.target.value);
    };
    const handleplaceholderChange = (e) => {
        setPlaceholder(e.target.value);
    };
    const handleMinimumOrderQuantityChange = (e) => {
        setMinimumOrderQuantity(e.target.value);
    };
    const handleUseChange = (e) => {
        setUse(e.target.value);
    };
    const handleMaterialChange = (e) => {
        setMaterial(e.target.value);
    };


    const handleUpload = async () => {
        try {
            setIsUploading(true);
            const storageRef = storage.ref();
            const fileRef = storageRef.child(file.name);
            await fileRef.put(file);
            const url = await fileRef.getDownloadURL();

            const newProduct = {
                id: uuidv4(), // Generate a unique ID
                title,
                price,
                placeholder,
                url,
                category,
                minimumOrderQuantity,
                use,
                material,
                additionalFields: additionalFields.join(", ")
            };

            await firestore.collection("product_description").doc(newProduct.id).set(newProduct);

            setCategory("");
            setTitle("");
            setPrice("");
            setPlaceholder("");
            setMinimumOrderQuantity("");
            setUse("");
            setMaterial("")
            setFile(null);
            setShowAlert(true);
            setIsUploading(false);

            setTimeout(() => {
                setShowAlert(false);
            }, 2000);
        } catch (error) {
            console.error("Error uploading product:", error);
            setIsUploading(false);
        }
    };


    const handleDelete = async (id,) => {
        try {
            await firestore.collection("product_description").doc(id).delete();
            // await storage.refFromURL(url).delete();
            alert("Image deleted successfully");
        } catch (error) {
            console.error("Error deleting image:", error);
        }
    };



    function EditForm({ product, handleSave, handleCancel }) {
        const [editedTitle, setEditedTitle] = useState(product.title);
        const [editedCategory, setEditedCategory] = useState(product.category);
        const [editedPrice, setEditedPrice] = useState(product.price);
        const [editedPlaceholder, setEditedPlaceholder] = useState(product.placeholder);
        const [editedMinimumOrderQuantity, setEditedMinimumOrderQuantity] = useState(product.minimumOrderQuantity);
        const [editedUse, setEditedUse] = useState(product.use);
        const [editeMaterial, setEditedMaterial] = useState(product.material);
        const [editedAdditionalFields, setEditedAdditionalFields] = useState(
            product.additionalFields ? product.additionalFields.split(",") : []
        );

        const handleAdditionalFieldChange = (index, value) => {
            const fields = [...editedAdditionalFields];
            fields[index] = value;
            setEditedAdditionalFields(fields);
        };



        const handleTitleChange = (e) => {
            setEditedTitle(e.target.value);
        };


        const handleCategoryChange = (e) => {
            setEditedCategory(e.target.value);
        };
        const handlePriceChange = (e) => {
            setEditedPrice(e.target.value);
        };
        const handlePlaceholderChange = (e) => {
            setEditedPlaceholder(e.target.value);
        };
        const handleMinimumOrderQuantityChange = (e) => {
            setEditedMinimumOrderQuantity(e.target.value);
        };
        const handleUseChange = (e) => {
            setEditedUse(e.target.value);
        };
        const handleMaterialChange = (e) => {
            setEditedMaterial(e.target.value);
        };

        //
        const handleAddField = () => {
            setEditedAdditionalFields([...editedAdditionalFields, ""]);
        };

        const removeField = (index) => {
            const fields = [...editedAdditionalFields];
            fields.splice(index, 1);
            setEditedAdditionalFields(fields);
        };

        const handleFieldChange = (index, value) => {
            handleAdditionalFieldChange(index, value);
        };
        const handleSaveClick = () => {
            handleSave({
                ...product,
                title: editedTitle,
                category: editedCategory,
                price: editedPrice,
                placeholder: editedPlaceholder,
                minimumOrderQuantity: editedMinimumOrderQuantity,
                use: editedUse,
                material: editeMaterial,
                additionalFields: editedAdditionalFields.join(", ")
            });
        };

        const handleCancelClick = () => {
            handleCancel();
        };

        return (
            <div className={`edit-modal  overflow-scroll ${showEditModal ? 'show' : ''}`}>
                <div className="modal-content grid grid-cols-3 gap-4 "> <h2 className="text-center model-title font-sans font-bold text-xl mb-4 ">Edit Product details</h2>
                    <input class="h-10 border mt-1 py-1 rounded px-4 w-full bg-gray-50" type="file" onChange={handleFileChangeEdit} />
                    <input
                        className=" h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        type="text"
                        placeholder="Product Name"
                        value={editedTitle}
                        onChange={handleTitleChange}
                    />
                    <input
                        className=" h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        type="text"
                        placeholder="Minimum Order Quantity"
                        value={editedMinimumOrderQuantity}
                        onChange={handleMinimumOrderQuantityChange}
                    />
                    <input
                        className=" h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        type="text"
                        placeholder="Uses"
                        value={editedUse}
                        onChange={handleUseChange}
                    />
                    <input
                        className=" h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        type="text"
                        placeholder="Material"
                        value={editeMaterial}
                        onChange={handleMaterialChange}
                    />
                    <input
                        className=" h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        type="text"
                        placeholder="Price"
                        value={editedPrice}
                        onChange={handlePriceChange}
                    />
                    <input
                        className=" h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                        type="text"
                        placeholder="Placeholder"
                        value={editedPlaceholder}
                        onChange={handlePlaceholderChange}
                    />

                    {editedAdditionalFields.map((field, index) => (
                        <div className="">
                            <input
                                className=" h-10 border mt-1 rounded px-1 w-full md:w-[300px] bg-gray-50"
                                key={index}
                                type="text"
                                placeholder="Additional Field"
                                value={field}
                                onChange={(e) => handleAdditionalFieldChange(index, e.target.value)}
                            />
                            <button
                                className="bg-red-500 text-white rounded-sm ml-2 px-3 my-2 py-1"
                                onClick={() => removeField(index)}
                            >
                                *
                            </button>

                        </div>

                    ))}



                    <select className="h-10 border mt-1 rounded px-4 w-full bg-gray-50" value={editedCategory} onChange={handleCategoryChange}>
                        <option>select category</option>
                        <option>Cotton Yarn Trading Division</option>
                        <option>Cotton Trading Division</option>
                        <option>Fabric Trading Division</option>
                        <option>Cotton Waste Trading Division</option>
                    </select>

                    <div className="modal-button-container">
                        <button className="bg-green-500 text-white rounded-sm px-4 py-2 mr-3" onClick={handleAddField}>
                            Add More
                        </button>
                        {isUpdating || updateAlert ? (
                            <div className="inline-flex items-end">
                                {isUpdating ? (
                                    <div className="spinner"></div>
                                ) : updateAlert ? (
                                    <div className="alert">Product update successfully</div>
                                ) : null}
                            </div>
                        ) : (
                            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={handleSaveClick}>Save</button>
                        )}
                        <button className="bg-red-500 py-2 px-4 rounded-md text-white ml-4" onClick={handleCancelClick}>Cancel</button>

                    </div>
                </div>
            </div>
        );
    }


    return (
        <div className="">
            <div className="">
                <div className="flex justify-between ">
                    <h2 className="text-lg text-blue-600 font-sans py-4 text-center xs:ml-[30%] md:ml-[50%] font-bold">Welcome Admin !</h2>
                    <div className="flex items-center justify-end xs:mr-4 mr-10 ">
                        <Link to= '/login'>
                        <button
                            className="inline-block xs:py-1 xs:px-2 rounded-lg bg-yellow-500 px-5 py-3 text-sm xs:text-[12px] font-medium text-white"
                        >
                            Log Out
                        </button>
                        </Link>
                    </div>
                </div>
 
                <div class="p-6 bg-gray-100 flex items-center justify-center">
                    <div class="container max-w-screen-lg mx-auto">
                        <div>
                            <h2 class="font-semibold text-xl mb-6 text-gray-600">Upload your Product From Here </h2>
                            {/* <p class="text-gray-500 mb-6">Form is mobile responsive. Give it a try.</p> */}

                            <div class="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
                                <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
                                    <div class="text-gray-600">
                                        <p class="font-medium text-lg">Product Details</p>
                                        <p>Please fill out all the fields.</p>
                                    </div>

                                    <div class="lg:col-span-2">
                                        <div class="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                                            <div class="md:col-span-3">
                                                <label for="full_name">Product Name</label>
                                                <input type="text"
                                                    placeholder="Product Name "
                                                    value={title}
                                                    required
                                                    onChange={handleTitleChange} class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                            </div>
                                            <div class="md:col-span-2">   <label for="full_name">select image</label> <input type="file" required class="h-10 border mt-1 rounded px-4 py-2 w-full bg-gray-50" onChange={editedProduct ? handleFileChangeEdit : handleFileChange} />
                                            </div>

                                            <div class="md:col-span-3">
                                                <label for="full_name">Minimum Order Quantity</label>
                                                <input type="text"
                                                    placeholder="Minimum Order Quantity "
                                                    value={minimumOrderQuantity}
                                                    required
                                                    onChange={handleMinimumOrderQuantityChange} class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                            </div>
                                            <div class="md:col-span-2">
                                                <label for="full_name">Price Range</label>
                                                <input type="text"
                                                    placeholder="Enter Price "
                                                    value={price}
                                                    required
                                                    onChange={handlePriceChange} class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                            </div>


                                            <div class="md:col-span-3">   <label for="full_name">Select Category</label> <select class="h-10  border mt-1 rounded px-4 py-2 w-full bg-gray-50" value={category} onChange={handleCategoryChange}>
                                                <option >
                                                    select cetegory
                                                </option>
                                                <option>
                                                    Cotton Trading Division
                                                </option>
                                                <option>
                                                    Fabric Trading Division
                                                </option>
                                                <option>
                                                    Cotton Waste Trading  Division
                                                </option>
                                                <option>
                                                    Cotton Yarn Trading Division
                                                </option>
                                            </select>

                                            </div>
                                            <div class="md:col-span-2">
                                                <label for="full_name">placeholder</label>
                                                <input type="text"
                                                    placeholder="Ex. no. of bales, no. of yarn or no. of something "
                                                    value={placeholder}
                                                    required
                                                    onChange={handleplaceholderChange} class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                            </div>
                                            <div class="md:col-span-3 my-2">
                                                <p for="full_name " className="text-[17px] text-yellow-700 font-medium">Product Specifications & Trade Information</p>
                                            </div>
                                            <div class="md:col-span-2">
                                            </div>
                                            <div class="md:col-span-3">
                                                <label for="full_name">Uses</label>
                                                <input type="text"
                                                    placeholder="Enter uses "
                                                    value={use}
                                                    required
                                                    onChange={handleUseChange} class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                            </div>
                                            <div class="md:col-span-2">
                                                <label for="full_name">Material</label>
                                                <input type="text"
                                                    placeholder="Enter Material "
                                                    value={material}
                                                    required
                                                    onChange={handleMaterialChange} class="h-10 border mt-1 rounded px-4 w-full bg-gray-50" />
                                            </div>



                                            {additionalFields.map((field, index) => (
                                                <div key={index} className="additional-field md:col-span-3 flex gap-2">
                                                    <input
                                                        type="text"
                                                        placeholder="Write something"
                                                        value={field}
                                                        class="h-10 border  mt-1 rounded px-4 w-full bg-gray-50"
                                                        onChange={(e) => handleAdditionalFieldChange(index, e.target.value)}
                                                    />
                                                    <button className="bg-red-500 text-white rounded-sm px-3 my-2 py-1" onClick={() => removeField(index)}>Remove</button>
                                                </div>
                                            ))}
                                            <div>  <button
                                                className="bg-green-500 py-2 px-6 rounded text-white"
                                                onClick={addField}
                                            >
                                                Add More
                                            </button></div>



                                            <div class="md:col-span-5 text-right">
                                                {isUploading || showAlert ? (
                                                    <div className="inline-flex items-end">
                                                        {isUploading ? (
                                                            <div className="spinner"></div>
                                                        ) : showAlert ? (
                                                            <div className="alert">Product uploaded successfully</div>
                                                        ) : null}
                                                    </div>
                                                ) : (
                                                    <button onClick={handleUpload} class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Submit</button>
                                                )}
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="mt-10  mb-10">
                <h2 className="text-center text-2xl mb-10 font-bold font-sans">List of  All Your Products</h2>
                {
                    isLoading ? (<div className="spinner"></div>) : (
                        <ul className="   gap-6">
                            {product_description.map((product) => (
                                <li key={product.id} className=" ">
                                    {editedProduct === product ? (
                                        <EditForm
                                            product={product}
                                            handleSave={handleSave}
                                            handleCancel={handleCancelEdit}
                                            handleFileChange={handleFileChangeEdit}
                                            editedAdditionalFields={editedAdditionalFields}
                                        />
                                    ) : (
                                        <>
                                            <div className=" border-gray-400 mx-10 my-3 lg:flex border-2">
                                                <img className="w-[200px] xs:w-full h-[150px]" src={product.url} alt={product.title} />
                                                <div className="ml-4">
                                                    <h3 className=" mt-2"><span className="text-sm font-semibold">product Name :</span> {product.title}</h3>
                                                    <h3 className=""><span className="text-sm font-semibold">product Category :</span> {product.category}</h3>
                                                    <h3 className=""><span className="text-sm font-semibold">Price Range :</span> {product.price}</h3>
                                                    <h3 className=""><span className="text-sm font-semibold">Minimum Order Quantity :</span> {product.minimumOrderQuantity}</h3>
                                                    <h3 className=""><span className="text-sm font-semibold">product uses :</span> {product.use}</h3>
                                                    <h3 className=""><span className="text-sm font-semibold">product material :</span> {product.material}</h3>

                                                    {/* {product.additionalFields ? <h3 className=""><span className="text-sm font-semibold">Specifications & Trade Information :</span>     {product.additionalFields && product.additionalFields.split(",")[1]}</h3> : ""} */}
                                                    {product.additionalFields ? <h3 className=""><span className="text-sm font-semibold">Specifications & Trade Information :</span>     {product.additionalFields && product.additionalFields.split(",")[0]} ||   {product.additionalFields && product.additionalFields.split(",")[1]} ||  {product.additionalFields && product.additionalFields.split(",")[2]} ||   {product.additionalFields && product.additionalFields.split(",")[3]} ||  {product.additionalFields && product.additionalFields.split(",")[4]} ||   {product.additionalFields && product.additionalFields.split(",")[5]}</h3> : ""}

                                                </div>

                                                <div>
                                                    <button
                                                        className="bg-red-500 py-2 px-4 rounded-md text-white ml-6"
                                                        onClick={() => handleDelete(product.id, product.url)}
                                                    >
                                                        Delete
                                                    </button>
                                                    <button
                                                        className="bg-blue-700 py-2 px-7 rounded-md text-white ml-10 my-4"
                                                        onClick={() => handleEdit(product)}
                                                    >
                                                        Edit
                                                    </button>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </li>
                            ))}

                        </ul>
                    )
                }
            </div>
        </div>
    );
}

export default Crud;
