import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'



const Nav = () => {

    const [nav, setNav] = useState(false)


    return (
        <div>
            <div onClick={() => setNav(!nav)}>
                <img className="absolute mt-6  right-4  cursor-pointer w-10 " src='assets/icon/menu.png'></img>
            </div>
            <div className={nav ? 'fixed top-0 left-0  h-screen bg-white z-10 duration-300' : 'fixed top-0 left-[-100%]  h-screen bg-white z-0 duration-300'}>

                <div onClick={() => setNav(!nav)}>
                    <img className="absolute mt-8 right-6 h-6 w-6 cursor-pointer " src='assets/icon/delete.png'></img>
                </div>
                <div > <img className=' pt-4 pl-1 bg-white ' src="assets/logo/logoIcon.png"></img>
                    </div>
                <nav>
                    
                    <ul className='flex flex-col bg-gray-00 px-8  text-gray-400'>
                        <li className='text-xl py-2 flex  ' onClick={() => setNav(!nav)} > <NavLink className='pr-44 rounded-lg pl-4 py-4' to='/' >  Home </NavLink> </li>
                        <li className='text-xl py-2 flex ' onClick={() => setNav(!nav)}> <NavLink className='pr-44 rounded-lg pl-4 py-4' to='/about' >  About </NavLink>  </li>
                        <li className='text-xl py-2 flex' onClick={() => setNav(!nav)}> <NavLink className='pr-44 rounded-lg pl-4 py-4' to='/services' >  Services </NavLink>  </li>
                        <li className='text-xl py-2 flex' onClick={() => setNav(!nav)}> <NavLink className='pr-44 rounded-lg pl-4 py-4' to='/product' >  Products </NavLink>  </li>
                        <li className='text-xl py-2 flex' onClick={() => setNav(!nav)}> <NavLink className='pr-44 rounded-lg pl-4 py-4' to='/gallery' >  Gallery </NavLink>  </li>
                        <li className='text-xl py-2 flex' onClick={() => setNav(!nav)}> <NavLink className='pr-44 rounded-lg pl-4 py-4' to='/contact' >  Contact </NavLink>  </li>
                    </ul>
                </nav>
                {/* <div className="flex p-6 "  >
                    <img className='p-2' src="Image/fb.svg"></img>
                    <img className='p-2' src="Image/insta.svg"></img>
                    <img className='p-2' src="Image/twitter.svg"></img>
                </div> */}
                
            </div>
        </div>
    )
}

export default Nav