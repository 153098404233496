import React from 'react';
import ModalImage from 'react-modal-image';
import './GalleryPage.css';

const Gallery = () => {
  const images = [
    { id: 1, src: "assets/gallery/s1.jpg", alt: 'Image 1' , title:"L.J. S.B. Registration Certificate" },
    { id: 1, src: "assets/gallery/s2.jpg", alt: 'Image 1',  title:"L.J. S.B. Enrolment Certificate" },
    { id: 1, src: "assets/gallery/s3.jpg", alt: 'Image 1', title: "L.J. S.B. PAN CARD" },
    { id: 1, src: "assets/gallery/s4.jpg", alt: 'Image 1', title: "L.J. S.B. GST Certificate" },
    { id: 1, src: "assets/gallery/s5.jpg", alt: 'Image 1', title:"L.J. Tex GST Certificate" },
    { id: 1, src: "assets/gallery/s6.jpg", alt: 'Image 1', title: "L.J. Tex Enrolment Certificate" },
    { id: 1, src: "assets/gallery/s7.jpg", alt: 'Image 1', title: "L.J. Tex Registration Certificate" },
    
  ];

  return (
<div>
<div>
        <img className='w-full' src='assets/banner/galleryy.svg'></img>
      </div>
    <div className="gallery-container grid-cols-1  gap-10 my-20 md:mx-20 grid md:grid-cols-3">
      {images.map((image, ) => (
       <div className=' md:w-[450px] transition-opacity   rounded-lg p-4 border-2 border-main-600 '> <div key={image.id} className="image-container">
          <ModalImage
            small={image.src}
            large={image.src}
            alt={image.alt}
            className="gallery-image"
          />
        </div> 
       <div> <p className='bg-main-600 py-4 text-center text-white'>{image.title}</p></div>
        
        </div>
      ))}
      
    </div>
    </div>
  );
};

export default Gallery;
