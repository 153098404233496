import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './BannerCarousel.css'
import { Link } from "react-router-dom";


const settings = {
  dots: false,
  fade: true,
  infinite: true,
  speed: 1000,
  autoplay: false,
  autoplaySpeed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,


};

const BannerCarusel = () => (

  <Slider {...settings}>
    <div>
      <div className="relative xs:hidden " >
        <img className="w-full " src="assets/banner/banner1.svg" alt="" />
        <Link to="/contact"> <button className="absolute top-[65%] left-[5%] m-4 px-[75px] py-4 hover:bg-[#0D2258] bg-main-600 rounded-[30px] text-white">Contact us</button></Link>
      </div>
      <div className="relative  xs:block hidden" >
        <img className="w-full   " src="assets/banner/bannerMobile.svg" alt="" />
        <Link to="/contact"> <button className="absolute top-[68%] left-[2%]  px-[40px] py-2 hover:bg-[#0D2258] bg-main-600 rounded-[30px] text-white">Contact us</button></Link>
      </div>
    </div>

  </Slider>
);
export default BannerCarusel
