import React, { useState, useEffect } from 'react';
import { products } from './ProductItem';
import './product.css'
import { Link } from 'react-router-dom';
import { firestore } from '../adminPanel/Firebase/firebase';
import axios from 'axios';
import { send } from 'emailjs-com';



const Produc = () => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [product_description, setproduct_description] = useState([]);
    const [countryCodes, setCountryCodes] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [selectedCountryCode, setSelectedCountryCode] = useState("+91");
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [inquiryData, setInquiryData] = useState({
        productName: '',
        FullName: "",
        quantity: '',
        mobileNumber: '',
        emailAddress: '',
        // placeholder: ""

    });

    useEffect(() => {
        const fetchCountryCodes = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v2/all');
                const countries = response.data;
                const codes = countries.map((country) => ({
                    label: `+${country.callingCodes[0]} (${country.name})`,
                    value: country.callingCodes[0],
                }));
                setCountryCodes(codes);
            } catch (error) {
                console.error('Error fetching country codes:', error);
            }
        };

        fetchCountryCodes();
    }, []);

    const handleCountryCodeChange = (e) => {
        setSelectedCountryCode(e.target.value);
    };

    useEffect(() => {
        const unsubscribe = firestore.collection("product_description").onSnapshot((snapshot) => {
            const ProductList = snapshot.docs.map((doc) => {
                return {
                    id: doc.id,
                    ...doc.data(),
                };
            });
            setproduct_description(ProductList);
            setLoading(false)
        });

        return () => {
            unsubscribe();
        };
    }, []);

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        // Send the inquiry data to emailjs
        // emailjs
        send(
            "service_kmkt2f6",
            "template_dwi256e",
            {
                ...inquiryData,
                country: selectedCountryCode, // Add the selected country code to the object
            },
            "CYG5oYv0iJlGQjq3G"
        )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert("Thanks for connecting us, we will reply shortly.")
                // Handle success, show a success message, etc.
            })
            .catch((err) => {
                console.log('FAILED...', err);
                // Handle error, show an error message, etc.
            });

        // Reset the input values
        setInquiryData({
            productName: '',
            quantity: '',
            mobileNumber: '',
            emailAddress: '',
            FullName: '',
        });

        // Close the modal
        closeModal();
    };
    const handleInputChange = (e) => {
        setInquiryData({
            ...inquiryData,
            [e.target.name]: e.target.value
        });
    };
    // const openModal = (name) => {
    //     setInquiryData({
    //         ...inquiryData, productName: name
    //     });
    //     setShowModal(true);
    // };
    const openModal = (name) => {
        const product = product_description.find((p) => p.title === name); // Find the product with the matching title
        const placeholder = product ? product.placeholder : ""; // Get the placeholder value if product is found
        setInquiryData({
            ...inquiryData,
            productName: name,
            placeholder: placeholder, // Set the placeholder value in the inquiryData state
        });
        setShowModal(true);
    };


    const closeModal = () => {
        setShowModal(false);
    };



    const filterProduct = selectedCategory === 'All' ? product_description : product_description.filter(product => product.category === selectedCategory);


    return (
        <div className="">
            <div className='grid md:grid-cols-8'>
                <div className="  md:col-span-2  ">
                    <div className='border md:mx-20 xs:mx-10   mt-8 h-[400px]'>
                        <h2 className={`pl-4 py-5  cursor-pointer ${selectedCategory === 'All' ? 'bg-gray-200 ' : ''}`} onClick={() => handleCategoryChange('All')}> All Products </h2>
                        <h2 className={`pl-4 py-5 mt-1 cursor-pointer ${selectedCategory === 'Cotton Yarn Trading Division' ? 'bg-gray-200' : ''}`} onClick={() => handleCategoryChange('Cotton Yarn Trading Division')} > Cotton Yarn Trading Division </h2>
                        <h2 className={`pl-4 py-5  mt-1  cursor-pointer ${selectedCategory === 'Cotton Trading Division' ? 'bg-gray-200' : ''}`} onClick={() => handleCategoryChange('Cotton Trading Division')}> Cotton Trading Division   </h2>
                        <h2 className={`pl-4 py-5  mt-1 cursor-pointer ${selectedCategory === 'Fabric Trading Division' ? 'bg-gray-200' : ''}`} onClick={() => handleCategoryChange('Fabric Trading Division')} > Fabric Trading Division </h2>
                        <h2 className={`pl-4 py-5  mt-1 cursor-pointer ${selectedCategory === 'Cotton Waste Trading Division' ? 'bg-gray-200' : ''}`} onClick={() => handleCategoryChange('Cotton Waste Trading Division')}  > Cotton Waste Trading  Division </h2>
                    </div>
                </div>
                {/* <div className="md:col-span-2">
                    <div className='border md:mx-20 xs:mx-10 mt-8 h-[400px]'>
                        {product_description.map((value, index) => {
                            const { category } = value
                            return (
                            <h2
                                key={index}
                                className={`pl-4 py-5 cursor-pointer ${selectedCategory === category ? 'bg-gray-200' : ''}`}
                                onClick={() => handleCategoryChange(category)}
                            >
                                {category}
                            </h2>
                        )
                        })}
                    </div>
                </div> */}
                <div className="md:col-span-6 ">
                    {/* <h1 className='text-center  my-20'>Product will be adding very soon....</h1> */}

                    {
                        loading ? (<div> <div className='spinner mt-4'></div> <h2 className='text-center mt-10  font-sans lg:text-2xl font-medium'>Product is Loading... please wait</h2> </div>) : (
                            <div className='my-10 xs:grid-cols-2  grid gap-x-1 gap-y-8 md:grid-cols-4'>
                                {filterProduct.map((value, index) => {
                                    const { id, title, url } = value
                                    return (
                                        <div key={id} className="product-item md:mx-4 xs:mx-1 md:rounded-lg border">
                                            <img className='mx-auto w-[193px] h-[149px] rounded-sm md:my-4' src={url} ></img>
                                            <p className='text-center font-Roboto  '>{title}</p>
                                            {/* <p>{product.rate}</p> */}
                                            <button className=' md:ml-7 xs:ml-3 text-[14px] my-3 border rounded-md py-1 xs:px-6 md:px-14 hover:bg-[#19AFCF] hover:text-white' onClick={() => openModal(title)}> Send Inquiry</button>
                                            {showModal && (
                                                <div className="modal">
                                                    <div className="modal-content">
                                                        <h2>Product Inquiry</h2>
                                                        <form onSubmit={handleSubmit}>
                                                            <input
                                                                type="text"
                                                                name="productName"
                                                                value={inquiryData.productName}
                                                                onChange={handleInputChange}
                                                                placeholder="Product Name"
                                                                readOnly
                                                            />
                                                            <input
                                                                type="text"
                                                                name="FullName"
                                                                value={inquiryData.FullName}
                                                                onChange={handleInputChange}
                                                                placeholder="Your Full Name"
                                                                required
                                                            />
                                                            <input
                                                                type="number"
                                                                name="quantity"
                                                                value={inquiryData.quantity}
                                                                onChange={handleInputChange}
                                                                placeholder={inquiryData.placeholder}
                                                                required
                                                            />
                                                            <div className='flex'>
                                                                <select className='w-12 border-l border-b border-t h-11 bg-white ' value={selectedCountryCode} onChange={handleCountryCodeChange}>
                                                                    <option value="">+91 (india)</option>
                                                                    {countryCodes.map((country, index) => (
                                                                        <option className='' key={index} value={country.value}>
                                                                            {country.label}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                <input

                                                                    type="number"
                                                                    name="mobileNumber"
                                                                    value={inquiryData.mobileNumber}
                                                                    onChange={handleInputChange}
                                                                    placeholder="Mobile Number"
                                                                    required
                                                                />

                                                            </div>
                                                            <input
                                                                type="email"
                                                                name="emailAddress"
                                                                value={inquiryData.emailAddress}
                                                                onChange={handleInputChange}
                                                                placeholder="Email Address"

                                                            />
                                                            <button type="submit">Submit</button>
                                                            <button onClick={closeModal}>Cancel</button>
                                                        </form>
                                                    </div>

                                                </div>

                                            )}

                                            {/* <button className=' md:ml-7 xs:ml-3 text-[14px] mb-3 border rounded-md py-1 xs:px-6 md:px-14 bg-[#19AFCF] hover:bg-[#137F96]  text-white'> More Details</button> */}
                                            <Link to={`/details/${id}`} ><button className='md:ml-7 xs:ml-3 text-[14px] mb-3 border rounded-md py-1 xs:px-6 md:px-14 bg-[#19AFCF] hover:bg-[#137F96] text-white'> More Details</button></Link>

                                        </div>
                                    )
                                }

                                )}


                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default Produc;






