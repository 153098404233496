
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { firestore } from '../adminPanel/Firebase/firebase';
import axios from 'axios';
import { send } from "emailjs-com";

function ProductDetails() {
    //     const [product_description, setproduct_description] = useState([]);
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [countryCodes, setCountryCodes] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState("+91");
    const [showModal, setShowModal] = useState(false);
    const [inquiryData, setInquiryData] = useState({
        productName: '',
        FullName: '',
        quantity: '',
        mobileNumber: '',
        emailAddress: '',
    });

    useEffect(() => {
        const fetchCountryCodes = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v2/all');
                const countries = response.data;
                const codes = countries.map((country) => ({
                    label: `+${country.callingCodes[0]} (${country.name})`,
                    value: country.callingCodes[0],
                }));
                setCountryCodes(codes);
            } catch (error) {
                console.error('Error fetching country codes:', error);
            }
        };

        fetchCountryCodes();
    }, []);

    const handleCountryCodeChange = (e) => {
        setSelectedCountryCode(e.target.value);
    };


    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const doc = await firestore.collection("product_description").doc(id).get();
                if (doc.exists) {
                    setProduct(doc.data());
                } else {
                    console.log("Product not found");
                }
            } catch (error) {
                console.error("Error fetching product:", error);
            }
        };

        fetchProduct();
    }, [id]);


    if (!product) {
        return <div><div className='spinner ' /> <div className='text-center my-4'>Loading.....</div></div>;
    }



    const handleSubmit = (e) => {
        e.preventDefault();

        // Send the inquiry data to emailjs
        // emailjs
            send(
                "service_kmkt2f6",
                "template_dwi256e",
                {
                    ...inquiryData,
                    country: selectedCountryCode, // Add the selected country code to the object
                },
                "CYG5oYv0iJlGQjq3G"
            )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert("Thanks for connecting us, we will reply shortly.")
                // Handle success, show a success message, etc.
            })
            .catch((err) => {
                console.log('FAILED...', err);
                // Handle error, show an error message, etc.
            });

        // Reset the input values
        setInquiryData({
            productName: '',
            quantity: '',
            mobileNumber: '',
            emailAddress: '',
            FullName: '',
        });

        // Close the modal
        closeModal();
    };


    const handleInputChange = (e) => {
        setInquiryData({
            ...inquiryData,
            [e.target.name]: e.target.value,
        });
    };

    const openModal = (name) => {
        setInquiryData({
            ...inquiryData,
            productName: name,
        });
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };



    return (
        <div>
            <div className="mt-4 text-center">
                <Link className="text-gray-600" to="/product">
                    Product /
                </Link>{' '}
                <a>{product.title}</a>
            </div>

            <div class="my-20 grid place-items-center font-mono">
                <div class="rounded-md bg-gray-100 shadow-lg">
                    <div class="md:flex px-1 leading-none max-w-4xl">
                        <div class="flex-none">
                            <img
                                src={product.url}
                                alt="pic"
                                class="h-56 w-60 rounded-md transform -translate-y-4 border-4 border-gray-300 shadow-lg"
                            />
                        </div>

                        <div class="flex-col">
                            <p class="pt-4 text-gray-800 text-center px-10 text-2xl font-bold">{product.title}</p>
                            <hr class="hr-text" data-content="" />
                            <p class=" pb-3  px-10 text-lg text-yellow-700 font-bold">Price And Quantity</p>
                            {product.price ? <p class=" text-gray-800 xs:text-center pb-3 px-10 text-[16px] font-mono font-medium">PRICE :- {product.price}</p> : ""}
                            {product.minimumOrderQuantity ? <p class=" text-gray-800 pb-3 xs:text-center  px-10 text-[16px] font-mono font-medium">MINIMUM ORDER QUANTITY :- {product.minimumOrderQuantity}</p> : ""}
                            <p class="  mt-3 mx-10 text-lg text-yellow-700 font-bold">Specifications & Trade Information</p>
                            {product.material ? <p class=" text-gray-800 xs:text-center px-10 text-[16px]  font-mono mt-3 mb-2 font-medium">PRODUCT MATERIAL  :- {product.material}</p> : ""}
                            {product.use ? <p class=" text-gray-800  px-10 text-[16px] pt-2 font-mono font-medium">USE :- {product.use}</p> : ""}
                            {product.additionalFields.split(",").map((field, index) => (
                                field && <h3 className="text-gray-800 px-10 font-mono py-2" key={index}><span className="text-sm text-gray-800 font-semibold"></span> {field}</h3>
                            ))}
                            <button className="md:ml-7 mt-4  xs:ml-3 text-[14px] my-3 border rounded-md py-3 text-white xs:px-6 md:px-10 bg-[#19AFCF]" onClick={() => openModal(product.title)}>
                                Send Inquiry
                            </button>
                            {showModal && (
                                <div className="modal">
                                    <div className="modal-content">
                                        <h2>Product Inquiry</h2>
                                        <form onSubmit={handleSubmit}>
                                            <input type="text" name="productName" value={inquiryData.productName} onChange={handleInputChange} placeholder="Product Name" readOnly />
                                            <input type="text" name="FullName" value={inquiryData.FullName} onChange={handleInputChange} placeholder="Your Full Name" required />
                                            <input type="number" name="quantity" value={inquiryData.quantity} onChange={handleInputChange} placeholder={product.placeholder} required />
                                            <div className='flex'>
                                                <select className='w-12 border-l border-b border-t h-11 bg-white ' value={selectedCountryCode} onChange={handleCountryCodeChange}>
                                                    <option value="">+91 (india)</option>
                                                    {countryCodes.map((country, index) => (
                                                        <option className='' key={index} value={country.value}>
                                                            {country.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                <input

                                                    type="number"
                                                    name="mobileNumber"
                                                    value={inquiryData.mobileNumber}
                                                    onChange={handleInputChange}
                                                    placeholder="Mobile Number"
                                                    required
                                                />

                                            </div>
                                            <input type="email" name="emailAddress" value={inquiryData.emailAddress} onChange={handleInputChange} placeholder="Email Address" />
                                            <button type="submit">Submit</button>
                                            <button onClick={closeModal}>Cancel</button>
                                        </form>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductDetails;


