import React from 'react'
import ModalImage from 'react-modal-image';
import './service.css';
import { Link } from 'react-router-dom';

function Service() {
  const images = [
    { id: 1, src: "assets/services/service1.jpg", alt: 'Image 1' },
    { id: 1, src: "assets/services/service2.jpg", alt: 'Image 2' },
    { id: 1, src: "assets/services/service3.jpg", alt: 'Image 3' },

  ];




  return (
    <div className=''>
      <div>
        <img className='w-full' src='assets/banner/servicess.svg'></img>
      </div>
      <div className='border md:mx-40 mb-10 mt-10'>
        <h1 className=' pt-10 font-baloobhai text-center font-semibold text-4xl text-main-600'>Textile Spinning Consultancy </h1>
        <div className='px-6  my-8'>
          <p className='font-Roboto'>Under Textile Spinning Consultancy Division as founder him self is a textile qualified person and having rich work experience of more than two Decades in textile spinning sector, can give a full guidance to set up a Spinning Unit right from factory land selection to the first container export (including guidance for building construction, machinery selection, quality, production, maintenance Schedule set up & Costing ) </p>
          <p className='font-Roboto'>In addition to above our enterprise focuses on working as a customer oriented service provider as Textile Spinning Consultancy Services, we pledge to serve each and every client with a quality accredited assortment of consultancy Apart from this also can give technical and administrative consultancy to existing Spinning Units to improve their quality, productivity, overall financial performance & Accounting..</p>
        </div>
      </div>
      <div className='text-center'>
        <Link  to="/contact#specific-div"><button className='border px-20 border-blue-800 text-blue-800 hover:bg-blue-800 hover:text-white hover:transition font-sans py-3'>Click Here For More Details</button></Link>
      </div>
      <div className="gallery-container grid-cols-1  gap-10 my-20 md:mx-20 grid md:grid-cols-3">
      {images.map((image) => (
       <div className=' md:w-[400px] transition-opacity   rounded-lg p-4 border-2 '> <div key={image.id} className="image-container">
          <ModalImage
            small={image.src}
            large={image.src}
            alt={image.alt}
            className="gallery-image"
          />
        </div> 
        
        </div>
      ))}
      
    </div>

    </div>
  )
}

export default Service